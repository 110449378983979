<template>
  <div
    class="overflow-y-auto bg-gradient-to-b from-secondary to-primary !rounded-[24px_24px_0_0] pt-2"
  >
    <div class="grid grid-cols-[80px_1fr_80px] !rounded">
      <van-cell
        :border="false"
        class="!p-0 !bg-transparent"
        @click="showWifiModal = true"
      >
        <template #title>
          <div
            class="h-full cursor-pointer flex flex-col gap-1 justify-center items-center w-full px-4"
          >
            <img
              src="../assets/images/wifi.png"
              alt="Wi-Fi Icon"
              class="w-5 h-5 invert brightness-0"
            />
            <p class="text-[10px] leading-none font-bold text-white">WiFi</p>
          </div>
        </template>
      </van-cell>
      <van-cell
        :title="tableNumber"
        :label="!isAcceptDelivery ? 'Table' : 'Office'"
        :border="false"
        class="!bg-transparent border-r-[1px] border-l-[1px] border-primary"
        title-class="text-white text-3xl font-bold text-right flex flex-row-reverse justify-center items-center"
        label-class="h-fit -rotate-90 !m-0 !text-[10px] !text-white font-medium tracking-wide"
      />
      <div
        :class="[
          'flex flex-col justify-center items-center h-[60px] text-xs text-white font-bold',
          serverCalled ? '' : '',
        ]"
        @click="!serverCalled ? callServer() : null"
      >
        <van-loading v-if="loading" />
        <div v-else class="flex flex-col justify-center items-center gap-1">
          <img class="w-5 m-0" src="../assets/images/bell.png" alt="" />
          <p
            v-if="!serverCalled"
            class="text-[10px] leading-none font-bold text-white"
          >
            Help
          </p>
          <p v-else class="text-[10px] leading-none font-bold text-white">
            {{ timer }} s
          </p>
        </div>
      </div>
    </div>
  </div>

  <van-dialog
    v-model:show="showWifiModal"
    title="Wi-Fi Details"
    :show-cancel-button="false"
    :show-confirm-button="false"
    close-on-click-overlay
    class="p-4 pt-2"
  >
    <div
      class="flex flex-col gap-2 pt-2 items-start border-t-[1px] border-gray-100"
    >
      <p class="flex gap-1 items-center pl-3 pr-1 py-1">
        <strong>SSID:</strong> {{ wifiSsid }}
      </p>
      <p class="flex gap-1 items-center pl-3 pr-1 py-1 w-full">
        <strong>Password:</strong>
        <span>{{ wifiPassword }}</span>
        <van-button
          type="primary"
          class="!p-1 !h-fit w-6 justify-self-end !ml-auto"
          @click="copyToClipboard(wifiPassword)"
        >
          <img src="@/assets/images/copy.svg" alt="" />
        </van-button>
      </p>
    </div>
  </van-dialog>
</template>

<script>
import { useOrderStore } from '@/store/useOrderStore'
import { storeToRefs } from 'pinia'
import { showDialog, showToast } from 'vant'
import { ref, toRefs } from 'vue'

export default {
  setup() {
    const orderStore = useOrderStore()
    const serverCalled = ref(false)
    const timer = ref(60)
    const loading = ref(false)
    let countdownInterval = null

    const callServer = async () => {
      loading.value = true
      try {
        const res = await requestHelp({
          supplierName: restaurantName.value,
          note: '',
          tableNumber: tableNumber.value,
        })
        if (res && res.status === 201) {
          showDialog({
            message:
              'Your server will be with your shortly, thank you for your patience.',
            confirmButtonText: 'Confirm',
          })
        }
      } catch (error) {
        console.log(error)
      } finally {
        serverCalled.value = true
        loading.value = false
        timer.value = 60
        countdownInterval = setInterval(() => {
          if (timer.value > 0) {
            timer.value--
          } else {
            clearInterval(countdownInterval)
            serverCalled.value = false
          }
        }, 1000)
      }
    }

    const showWifiModal = ref(false)

    const copyToClipboard = (text) => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(
          () => {
            showToast('Password Copied')
          },
          (err) => {
            console.error('Clipboard write failed: ', err)
          }
        )
      } else {
        // Fallback for browsers that do not support navigator.clipboard
        const tempInput = document.createElement('input')
        tempInput.value = text
        document.body.appendChild(tempInput)
        tempInput.select()
        try {
          document.execCommand('copy')
          showToast('Password Copied')
        } catch (err) {
          console.error('Fallback copy failed: ', err)
        }
        document.body.removeChild(tempInput)
      }
    }

    const { restaurant, tableNumber } = storeToRefs(orderStore)

    const {
      restaurantName,
      restaurantLogo,
      wifiSsid,
      wifiPassword,
      isAcceptDelivery,
    } = toRefs(restaurant.value)

    const { requestHelp, backToHome } = orderStore

    return {
      restaurantName,
      restaurantLogo,
      tableNumber,
      callServer,
      serverCalled,
      timer,
      loading,
      requestHelp,
      backToHome,
      showWifiModal,
      copyToClipboard,
      wifiSsid,
      wifiPassword,
      isAcceptDelivery,
    }
  },
}
</script>

<style scope>
.van-dialog__header {
  padding: 0.5rem;
  text-align: left;
}
</style>
