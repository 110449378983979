import { getCustomers, getCustomer, createCustomer } from '@/api/customers'

export const fetchAllCustomers = async () => {
  try {
    const customers = await getCustomers()
    return customers.data
  } catch (error) {
    console.error(error.message)
    throw error
  }
}

export const fetchCustomerById = async (id) => {
  try {
    const customer = await getCustomer(id)
    return customer.data
  } catch (error) {
    console.error(error.message)
    throw error
  }
}

export const addNewCustomer = async (supplierName) => {
  try {
    const newCustomer = await createCustomer(supplierName)
    return newCustomer.data
  } catch (error) {
    console.error(error.message)
    throw error
  }
}
