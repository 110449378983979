import { getSupplier, requestService } from '@/api/supplier'

export const fetchSupplierByName = async (id) => {
  try {
    const supplier = await getSupplier(id)
    return supplier.data
  } catch (error) {
    console.error('Error fetching supplier:', error.message)
    throw error
  }
}

export const requestSupplierService = async (data) => {
  try {
    const response = await requestService(data)
    return response
  } catch (error) {
    console.error('Error requesting supplier service:', error.message)
    throw error
  }
}
