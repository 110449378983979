import { sendOrder } from '@/api/salesOrder.js'

export const postSalesOrder = async (order) => {
  try {
    const orderConfirmation = await sendOrder(order)
    return orderConfirmation
  } catch (error) {
    console.error(error.message)
    throw error
  }
}
