<template>
  <van-image-preview
    v-model:show="showPromo"
    z-index="9999"
    :images="promotions"
    :show-indicators="true"
    :show-index="false"
    :on-close="onClosePromotion"
    :closeable="true"
  >
    <template #image="{ src }"
      ><img class="max-w-[288px] rounded-xl mx-auto" :src="src"
    /></template>
  </van-image-preview>
</template>

<script>
import { useOrderStore } from '@/store/useOrderStore'
import { storeToRefs } from 'pinia'

export default {
  setup() {
    const orderStore = useOrderStore()

    const { restaurant, showPromo } = storeToRefs(orderStore)

    const { onClosePromotion } = orderStore

    const promotions = restaurant.value.promotions.files.map((i) => i.file_url)

    return {
      promotions,
      showPromo,
      onClosePromotion,
    }
  },
}
</script>
