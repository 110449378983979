<template>
  <Splash v-if="loading" />
  <div v-else class="!max-h-[100dvh]">
    <Header />
    <div ref="contentEl" class="fixed max-w-full !h-[calc(100dvh-46px)]">
      <div
        class="absolute z-[9999] p-1 w-[calc(100%-160px)] h-[68px] left-1/2 -translate-x-1/2"
        @mousedown="startDrag"
        @touchstart="startDrag"
      >
        <div class="drag-bar z-50"></div>
      </div>

      <InfoBar />
      <Banners />
      <Menu />
    </div>
    <PromoPreview />

    <!-- Confirm Button and Total Price -->
    <van-action-bar class="fixed bottom-0 !z-[2999] !bg-primary !w-full">
      <a href="http://www.nightout-app.com" target="_blank"
        ><img
          class="w-8 ml-4 brightness-0 invert"
          src="../assets/images/nightout-logo.svg"
          alt="night out logo"
      /></a>

      <van-action-bar-button
        type="default"
        text="View Order"
        :disabled="loading ? true : false"
        class="!m-0 max-w-[200px] !ml-auto !mr-2 !bg-gradient-to-r !from-night_purple !to-violet-900 !text-white"
        @click="goToConfirmation"
      />
    </van-action-bar>
  </div>
</template>
<script>
import { ref, toRefs, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useOrderStore } from '@/store/useOrderStore'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'
import Header from '@/components/Header.vue'
import Banners from '@/components/Banners.vue'
import Menu from '@/components/Menu.vue'
import PromoPreview from '@/components/PromoPreview.vue'
import InfoBar from '@/components/InfoBar.vue'
import Splash from '@/components/splash.vue'

export default {
  name: 'HomeView',
  components: {
    Header,
    Banners,
    Menu,
    PromoPreview,
    InfoBar,
    Splash,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const orderStore = useOrderStore()

    const storedId = ref('')
    const customerIdLocal = ref(null)
    const contentEl = ref(null)
    const isDragging = ref(false)
    const initialY = ref(0)
    const offsetY = ref(0)
    const show = ref(false)

    const {
      totalPrice,
      totalQuantity,
      selectedItems,
      updateItemQuantity,
      serverCalled,
      timer,
      restaurant,
      loading,
      tableNumber,
      currentY,
      storeInitialized,
    } = storeToRefs(orderStore)

    const { restaurantName, restaurantLogo, categories } = toRefs(
      restaurant.value
    )

    const {
      loadSupplierData,
      requestHelp,
      loadBanners,
      generateCustomer,
      getCustomerById,
      updateCurrentY,
      MIN_SCROLL,
      MAX_SCROLL,
    } = orderStore

    const showPopup = () => {
      if (!loading.value) {
        show.value = !show.value
      }
    }

    const goToConfirmation = () => {
      router.push('/confirmation')
    }

    const startDrag = (event) => {
      event.preventDefault()
      isDragging.value = true

      initialY.value =
        event.type === 'touchstart' ? event.touches[0].clientY : event.clientY
      offsetY.value = contentEl.value.getBoundingClientRect().top

      document.addEventListener('mousemove', onDrag)
      document.addEventListener('mouseup', stopDrag)
      document.addEventListener('touchmove', onDrag)
      document.addEventListener('touchend', stopDrag)
    }

    const onDrag = (event) => {
      if (isDragging.value) {
        const clientY =
          event.type === 'touchmove' ? event.touches[0].clientY : event.clientY
        const deltaY = clientY - initialY.value
        updateCurrentY(offsetY.value + deltaY)

        if (currentY.value < MIN_SCROLL) {
          updateCurrentY(MIN_SCROLL)
        } else if (currentY.value > MAX_SCROLL) {
          updateCurrentY(MAX_SCROLL)
        }

        contentEl.value.style.top = `${currentY.value}px`
      }
    }

    const stopDrag = () => {
      isDragging.value = false

      document.removeEventListener('mousemove', onDrag)
      document.removeEventListener('mouseup', stopDrag)
      document.removeEventListener('touchmove', onDrag)
      document.removeEventListener('touchend', stopDrag)

      const snapTo =
        currentY.value < (MIN_SCROLL + MAX_SCROLL) / 2 ? MIN_SCROLL : MAX_SCROLL
      currentY.value = snapTo

      contentEl.value.style.transition = 'top 0.3s ease'
      contentEl.value.style.top = `${snapTo}px`

      setTimeout(() => {
        contentEl.value.style.transition = ''
      }, 300)
    }

    watch(currentY, (newVal) => {
      if (contentEl.value && newVal !== null && newVal !== undefined) {
        contentEl.value.style.top = `${newVal}px`
        contentEl.value.style.transition = 'top 0.3s ease'
      }
    })

    onMounted(async () => {
      const { tableNumber, resId } = route.params

      if (contentEl.value) {
        contentEl.value.style.top = `${currentY.value}px`
      }

      const storedOrderData = localStorage.getItem('orderStore')
        ? JSON.parse(localStorage.getItem('orderStore'))
        : null

      if (route.params.delivery) {
        restaurant.value.isAcceptDelivery = true
      }

      if (typeof window !== 'undefined') {
        storedId.value = localStorage.getItem(
          `${restaurantName.value}_customerId`
        )

        if (storedId.value) {
          customerIdLocal.value = storedId.value
        }

        if (storedOrderData) {
          orderStore.$patch({
            restaurant: {
              isAcceptDelivery: false,
            },
          })
        }
      }

      if (route.meta.from.path !== '/confirmation') {
        try {
          await loadSupplierData(resId, tableNumber)
          if (
            customerIdLocal.value &&
            customerIdLocal.value === storedId.value
          ) {
            getCustomerById(customerIdLocal.value)
          } else {
            generateCustomer()
          }
        } catch (error) {
          console.error('Error loading supplier data or customer info:', error)
        }
      }
    })

    return {
      categories,
      restaurantLogo,
      totalPrice,
      totalQuantity,
      selectedItems,
      show,
      showPopup,
      goToConfirmation,
      restaurantName,
      tableNumber,
      updateItemQuantity,
      serverCalled,
      timer,
      loading,
      requestHelp,
      loadBanners,
      contentEl,
      startDrag,
      storeInitialized,
    }
  },
}
</script>

<style scoped>
.van-action-bar-icon {
  background-color: #000;
}

.drag-bar {
  margin-top: 2px;
  height: 4px;
  max-width: 33vw;
  margin-inline: auto;
  border-radius: 50px;
  background-color: #ffffff30;
  cursor: pointer;
}
</style>
