import apiClient from './apiClient'

export const getCustomers = async () => {
  return apiClient.get('/v1/customer')
}

export const getCustomer = async (id) => {
  return apiClient.get(`/v1/customer/${id}`)
}

export const createCustomer = async (body) => {
  return apiClient.post('/v1/customer/generate', body)
}
