import { SalesOrderTypeEnum, CURRENCY } from '@/utils/contants'

export const OrderDTO = {
  items: [],
  notes: '',
  servingFlow: '',
  currency: CURRENCY.USD,
  customerName: '',
  tableNumber: '',
  supplierName: '',
  salesOrderType: SalesOrderTypeEnum.DINE_IN,
  phoneNumber: undefined,
}

export const RestaurantDTO = {
  restaurantName: '',
  restaurantId: '',
  restaurantLogo: '',
  wifiSsid: '',
  wifiPassword: '',
  banners: [],
  promotions: [],
  categories: [],
  isAcceptDelivery: false,
  isSupportBanners: true,
}

export const CustomerDTO = {
  name: '',
  creation: '',
  modified: '',
  modified_by: '',
  customer_name: '',
  gender: null,
  image: null,
  mobile_no: null,
  email_id: null,
  primary_address: null,
}
