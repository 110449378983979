export const SalesOrderTypeEnum = Object.freeze({
  DINE_IN: 'Dine-in',
  DELIVERY: 'Delivery',
})

export const CURRENCY = Object.freeze({
  USD: 'USD',
  EUR: 'EUR',
  RIEL: 'RIEL',
})

export const SalesOrderServingFlowEnum = Object.freeze({
  APPETIZERS_FIRST: 'Appetizers-First',
  ALL_AT_ONCE: 'All-At-Once',
})
