<template>
  <div class="splash-bg flex justify-center items-center h-[100dvh]">
    <img
      class="brightness-0 invert w-1/2 max-w-40"
      src="../assets/images/nightout-logo.svg"
      alt="Night Out Logo"
    />
  </div>
</template>

<style scoped>
.splash-bg {
  background: radial-gradient(circle, #7042ea, #070707);
  background-size: 150% 150%;
  animation: radial-gradient-animation 15s linear infinite;
}
@keyframes radial-gradient-animation {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
</style>
