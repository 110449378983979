<template>
  <van-swipe
    v-if="isSupportBanners && banners"
    autoplay="5000"
    :loop="true"
    :show-indicators="false"
    class="!bg-white"
  >
    <van-swipe-item
      v-for="(banner, index) in banners"
      :key="index"
      class="bg-primary"
    >
      <a :href="banner.file_link" target="_blank">
        <van-image
          :src="banner.file_url"
          width="100vw"
          height="80px"
          fit="cover"
          alt="Image"
          class="w-full"
        />
      </a>
    </van-swipe-item>
  </van-swipe>
</template>

<script>
import { useOrderStore } from '@/store/useOrderStore'
import { storeToRefs } from 'pinia'
import { toRefs } from 'vue'

export default {
  setup() {
    const orderStore = useOrderStore()
    const { restaurant } = storeToRefs(orderStore)

    const { banners, isSupportBanners } = toRefs(restaurant.value)

    return { banners, isSupportBanners }
  },
}
</script>
