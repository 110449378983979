<template>
  <div class="bg-night_purple">
    <Header />
    <div
      class="mt-[46px] !h-[calc(100dvh-258px)] overflow-scroll !bg-primary !rounded-[24px_24px_0_0]"
    >
      <van-cell
        class="!sticky top-0 z-[9999] !bg-primary border-b-[1px] border-white border-opacity-10"
        value-class="flex justify-between items-center !text-white"
        :border="false"
      >
        <span>Cart ({{ totalQuantity }})</span>
        <van-button
          class="!border-0 flex items-center gap-2 !bg-transparent !text-white !h-8 !p-0"
          icon="delete-o"
          @click="emptyCart"
        >
          Clear Cart
        </van-button>
      </van-cell>

      <van-list v-if="selectedItems.length > 0" class="w-full">
        <div v-for="category in selectedItems" :key="category.categoryName">
          <span
            class="block text-[14px] text-white px-8 py-1 bg-secondary w-[calc(100% + 16px)] -ml-4"
          >
            {{ category.categoryName }}
          </span>
          <van-cell
            v-for="item in category.items"
            :key="item.id"
            class="w-full px-4 pb-4 first:pt-4 !bg-primary !border-red-500"
          >
            <!-- Price and Quantity -->
            <template #title>
              <div class="flex gap-2">
                <van-image
                  class="aspect-square min-w-[90px]"
                  fit="cover"
                  radius="6"
                  width="90"
                  height="90"
                  :src="item.image"
                />
                <div class="flex flex-col justify-between w-full">
                  <div>
                    <p class="text-[15px] font-bold text-white">
                      {{ item.name }}
                    </p>
                    <p class="text-[13px] text-[#999999]">
                      {{ item.description }}
                    </p>
                  </div>

                  <div class="flex justify-between items-center">
                    <span class="price font-semibold">
                      <span class="text-xs text-white">$</span>
                      <span class="text-xl text-white">{{
                        Math.floor(item.rate)
                      }}</span>
                      <span class="text-xs text-white"
                        >.{{ (item.rate % 1).toFixed(2).substring(2) }}</span
                      >
                    </span>
                    <van-stepper
                      v-model="item.quantity"
                      button-size="20"
                      disable-input
                      class="flex items-center"
                      min="0"
                    />
                  </div>
                </div>
              </div>
            </template>
          </van-cell>
        </div>
      </van-list>
      <div
        v-else
        class="w-full h-[calc(100dvh-311px)] !bg-primary flex items-center"
      >
        <van-cell
          title="You haven't ordered anything."
          value="Your added items will appear here."
          class="!text-white !bg-transparent flex flex-col items-center justify-center"
          value-class="text-[#999999]"
        />
      </div>
    </div>

    <van-cell-group
      class="!absolute !bottom-[80px] left-0 right-0 !m-0 max-w-[100vw] !bg-primary !border-t-[1px] !border-white !border-opacity-15 !rounded-none"
      inset
    >
      <van-field
        v-if="!restaurant.isAcceptDelivery"
        v-model="orderFlowValue"
        class="!bg-transparent"
        label-class="!text-white"
        is-link
        readonly
        :required="true"
        label="Service"
        placeholder="Choose Service Type"
        @click="showPicker = true"
      />
      <van-popup
        v-model:show="showPicker"
        class="!bg-secondary"
        round
        position="bottom"
      >
        <van-picker
          title="Choose"
          title-class="!text-white"
          class="mb-[177px] !bg-secondary"
          confirm-button-text="Confirm"
          cancel-button-text="Cancel"
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
        />
      </van-popup>
      <van-field
        v-if="restaurant.isAcceptDelivery"
        v-model="order.phoneNumber"
        class="!bg-transparent"
        label-class="!text-white"
        label="Phone"
        placeholder="Enter your phone number"
        :required="restaurant.isAcceptDelivery ? true : false"
        type="number"
        maxlength="15"
      />
      <van-field
        v-model="order.notes"
        class="!bg-transparent !text-white"
        label-class="!text-white"
        rows="2"
        autosize
        label="Message"
        type="textarea"
        maxlength="100"
        placeholder="Special Requests..."
        show-word-limit
      />
    </van-cell-group>
    <van-loading
      v-if="orderSent"
      class="!absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      color="#1989fa"
    />
  </div>
  <!-- Confirm Button and Total Price -->
  <van-action-bar
    class="z-[2999] pt-0 flex flex-col !h-[80px] justify-between absolute bottom-0 left-0 right-0"
  >
    <van-cell
      class="!p-0 !py-2 !bg-primary border-t-[1px] border-white border-opacity-15"
      title-class="font-bold flex justify-between px-4"
      :border="false"
    >
      <template #title
        ><span class="text-white font-normal">Total:</span
        ><span class="text-night_green"> ${{ totalPrice }}</span></template
      >
    </van-cell>
    <van-action-bar-button
      text="Place Order"
      class="!rounded-none !text-white !bg-gradient-to-r !from-night_purple !to-violet-900"
      :disabled="orderSent || totalQuantity === 0 || !orderFlowValue"
      @click="sendOrder(order)"
    />
  </van-action-bar>
</template>
<script>
import { useOrderStore } from '@/store/useOrderStore'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { showDialog } from 'vant'
import Header from '@/components/Header.vue'
import { SalesOrderServingFlowEnum, SalesOrderTypeEnum } from '@/utils/contants'

export default {
  name: 'OrderConfirmationView',
  components: {
    Header,
  },
  setup() {
    const DINE_IN = SalesOrderTypeEnum.DINE_IN
    const DELIVER = SalesOrderTypeEnum.DELIVERY
    const APPETIZERS_FIRST = SalesOrderServingFlowEnum.APPETIZERS_FIRST
    const ALL_AT_ONCE = SalesOrderServingFlowEnum.ALL_AT_ONCE

    const orderStore = useOrderStore()
    const {
      order,
      selectedItems,
      totalPrice,
      totalQuantity,
      tableNumber,
      restaurant,
      phoneNumber,
    } = storeToRefs(orderStore)
    const { placeOrder, clearCart, backToHome } = orderStore

    const orderSent = ref(false)
    const showPicker = ref(false)
    const orderFlowValue = ref('')

    if (order.value && order.value.servingFlow) {
      orderFlowValue.value = replaceHyphensWithSpaces(order.value.servingFlow)
    }

    const columns = [
      { text: 'Appetizers First', value: APPETIZERS_FIRST },
      { text: 'All at Once', value: ALL_AT_ONCE },
    ]

    function replaceHyphensWithSpaces(str) {
      return str.replace(/-/g, ' ')
    }

    const emptyCart = () => {
      orderStore.clearCart()
    }

    const onConfirm = ({ selectedOptions }) => {
      showPicker.value = false
      order.value.servingFlow = selectedOptions[0].value
      orderFlowValue.value = replaceHyphensWithSpaces(order.value.servingFlow)
    }

    const sendOrder = async (order) => {
      try {
        orderSent.value = true
        const res = await placeOrder(order)

        if (res && res.status === 201) {
          await showDialog({
            message: 'Your order has been placed. Thank you for your patience!',
            confirmButtonText: 'Confirm',
          })
        } else {
          await showDialog({
            message:
              "Sorry, we couldn't complete your order. Please try Again.",
            confirmButtonText: 'Confirm',
          })
        }

        clearCart()
        backToHome()
        orderSent.value = false
      } catch (error) {
        console.error('Error placing order:', error)
      }
    }

    return {
      selectedItems,
      totalPrice,
      totalQuantity,
      tableNumber,
      columns,
      showPicker,
      onConfirm,
      order,
      placeOrder,
      sendOrder,
      orderSent,
      restaurant,
      phoneNumber,
      orderFlowValue,
      emptyCart,
      DELIVER,
      DINE_IN,
      ALL_AT_ONCE,
    }
  },
}
</script>

<style scoped>
::v-deep(.van-picker-column__item--selected) {
  color: #ffffff !important;
  background-color: transparent;
}
::v-deep(.van-picker-column__item) {
  color: #ffffff90;
}
::v-deep(.van-picker__confirm) {
  color: #ffffff90;
}
::v-deep(.van-picker__title) {
  color: #ffffff !important;
}
::v-deep(.van-field__control) {
  color: #ffffff90 !important;
}
::v-deep(.van-picker__mask) {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.3)
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
}
::v-deep(.van-cell::after) {
  border-bottom: 1px solid #ffffff10;
}
</style>
