import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from './router'
import './index.css'
import {
  NavBar,
  Cell,
  CellGroup,
  Field,
  List,
  Sidebar,
  SidebarItem,
  Stepper,
  Swipe,
  SwipeItem,
  Image,
  ActionBar,
  ActionBarIcon,
  ActionBarButton,
  Popup,
  Button,
  Dialog,
  Picker,
  Overlay,
  Skeleton,
  SkeletonTitle,
  SkeletonImage,
  SkeletonAvatar,
  SkeletonParagraph,
  ImagePreview,
  Checkbox,
  CheckboxGroup,
  Loading,
  Toast,
} from 'vant'
import 'vant/lib/index.css'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

// Create Vue app instance
const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

// Register Vant components var nameglobally
app.use(NavBar)
app.use(Sidebar)
app.use(SidebarItem)
app.use(Cell)
app.use(CellGroup)
app.use(Field)
app.use(List)
app.use(Stepper)
app.use(Button)
app.use(Popup)
app.use(Swipe)
app.use(SwipeItem)
app.use(Image)
app.use(ActionBar)
app.use(ActionBarIcon)
app.use(ActionBarButton)
app.use(Dialog)
app.use(Overlay)
app.use(Picker)
app.use(Skeleton)
app.use(SkeletonTitle)
app.use(SkeletonImage)
app.use(SkeletonAvatar)
app.use(SkeletonParagraph)
app.use(ImagePreview)
app.use(Checkbox)
app.use(CheckboxGroup)
app.use(Loading)
app.use(Toast)
// Use Pinia and Router
app.use(pinia)
app.use(router)

// Mount the app to the DOM
app.mount('#app')
