<template>
  <van-nav-bar
    class="!fixed top-0 left-0 right-0 !bg-transparent"
    :border="false"
    :style="{
      opacity: !isCheckoutScreen && logoOpacity,
    }"
    left-arrow
    @click="backToHome"
  >
    <template #right>
      <div
        v-if="isCheckoutScreen"
        class="text-white font-medium"
        :border="false"
      >
        Order Details
      </div>
      <div
        v-else
        class="flex items-center transition"
        :style="{ opacity: logoOpacity }"
      >
        <img
          :src="restaurantLogo"
          alt="Logo"
          class="w-8 h-8 mr-2 rounded-full"
        />
        <span class="text-white">{{ restaurantName }}</span>
      </div>
    </template>
  </van-nav-bar>
  <div
    v-if="!isCheckoutScreen"
    class="transition delay-50 !sticky !top-5"
    :style="{
      opacity: stickyLogoOpacity,
    }"
  >
    <div class="grid place-items-center h-[28dvh]">
      <div class="flex flex-col items-center gap-2">
        <img :src="restaurantLogo" alt="Logo" class="w-9 h-9 rounded-full" />
        <span class="text-white">{{ restaurantName }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { useOrderStore } from '@/store/useOrderStore'
import { storeToRefs } from 'pinia'
import { ref, toRefs, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'

export default {
  setup() {
    const orderStore = useOrderStore()
    const route = useRoute()

    const logoOpacity = ref(0)
    const stickyLogoOpacity = ref(1)
    const isCheckoutScreen = ref(false)

    const { restaurant, tableNumber, currentY } = storeToRefs(orderStore)

    const {
      restaurantName,
      restaurantLogo,
      wifiSsid,
      wifiPassword,
      isAcceptDelivery,
    } = toRefs(restaurant.value)

    const { requestHelp, backToHome, MIN_SCROLL } = orderStore

    const setLogosOpacity = () => {
      if (currentY.value === MIN_SCROLL) {
        logoOpacity.value = 1

        stickyLogoOpacity.value = 0
      } else {
        logoOpacity.value = 0
        stickyLogoOpacity.value = 1
      }
    }

    watch(
      () => route.path,
      (newPath) => {
        isCheckoutScreen.value = newPath === '/confirmation'
      },
      { immediate: true }
    )

    if (currentY) {
      watch(currentY, () => setLogosOpacity())
    }

    onMounted(async () => {
      setLogosOpacity()
    })

    return {
      restaurantName,
      restaurantLogo,
      tableNumber,
      requestHelp,
      backToHome,
      wifiSsid,
      wifiPassword,
      isAcceptDelivery,
      logoOpacity,
      stickyLogoOpacity,
      isCheckoutScreen,
    }
  },
}
</script>
<style scope>
.van-icon-arrow-left::before {
  color: #ffffff;
}
</style>
