<template>
  <div>
    <van-sidebar
      v-model="activeCategory"
      class="flex gap-3 px-4 !pt-2 !pb-3 !w-[100vw] bg-primary overflow-scroll"
    >
      <van-button
        type="primary"
        class="!min-w-fit !h-fit text-[13px] !px-2 !rounded-full text-[#7d7e80] !bg-night_purple aspect-square !border-0"
        @click="showPromo = true"
      >
        <van-image
          class="invert"
          fit="contain"
          width="20"
          :src="require('@/assets/images/megaphone.png')"
        />
      </van-button>
      <van-sidebar-item
        v-for="(category, index) in restaurant.categories"
        :key="index"
        :title="category.text"
        :class="[
          '!min-w-fit text-[13px] !py-2 !px-4 rounded-full !bg-secondary !text-white border-[1px] border-[#ffffff10]',
          index === activeCategory ? '' : '',
        ]"
      />
    </van-sidebar>

    <van-list
      id="menuScroll"
      class="w-full h-full overflow-scroll !bg-primary !border-0"
      :class="{
        '!h-[calc(100dvh-308.5px)]':
          restaurant.isSupportBanners && restaurant.banners.length,
        '!h-[calc(100dvh-222px)]': !(
          restaurant.isSupportBanners && restaurant.banners.length
        ),
      }"
      @scroll="handleScroll"
    >
      <van-cell
        v-for="item in filteredMenuItems"
        :key="item.id"
        class="w-full px-4 first:!pt-0 !pb-1 !bg-transparent"
      >
        <!-- Price and Quantity -->
        <template #title>
          <div
            class="flex gap-2 bg-secondary px-2 py-2 rounded-md border-[1px] border-[#ffffff10]"
          >
            <van-image
              radius="6"
              class="aspect-square min-w-[90px]"
              fit="cover"
              width="90"
              height="90"
              :src="item.image"
              @click="showOverlay(item.image)"
            />
            <van-overlay
              :show="show"
              z-index="9999"
              class="bg-transparent"
              @click="show = false"
            >
              <div class="flex justify-center items-center h-full">
                <van-image
                  :src="currentImage"
                  width="80%"
                  height="80%"
                  fit="contain"
                />
              </div>
            </van-overlay>
            <div class="flex flex-col justify-between w-full">
              <div>
                <p class="text-[15px] font-bold text-white">{{ item.name }}</p>
                <p class="text-[13px] !text-white !text-opacity-60">
                  {{ item.description }}
                </p>
              </div>

              <div class="flex justify-between items-center">
                <span class="price font-semibold text-white">
                  <span class="text-xs">$</span>
                  <span class="text-xl">{{ Math.floor(item.rate) }}</span>
                  <span class="text-xs"
                    >.{{ (item.rate % 1).toFixed(2).substring(2) }}</span
                  >
                </span>
                <van-stepper
                  v-model="item.quantity"
                  button-size="20"
                  disable-input
                  class="flex items-center"
                  min="0"
                  @change="updateQuantity(item, $event)"
                />
              </div>
            </div>
          </div>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useOrderStore } from '@/store/useOrderStore'
import { storeToRefs } from 'pinia'

export default {
  setup() {
    const orderStore = useOrderStore()

    const activeCategory = ref(0)
    const show = ref(false)
    const currentImage = ref('')

    const {
      restaurant,
      totalQuantity,
      selectedItems,
      showPromo,
      isScrolled,
      currentY,
    } = storeToRefs(orderStore)

    const { updateCurrentY, MIN_SCROLL } = orderStore

    const showOverlay = (image) => {
      currentImage.value = image
      show.value = true
    }

    const handleScroll = (event) => {
      const { target } = event
      if (!target) return

      const scrollTop = target.scrollTop
      const maxScrollTop = target.scrollHeight - target.clientHeight

      if (scrollTop >= maxScrollTop - 50 && currentY.value > MIN_SCROLL) {
        updateCurrentY(MIN_SCROLL)
      }
    }

    const filteredMenuItems = computed(() => {
      const category = restaurant.value.categories[activeCategory.value]
      return category ? category.children : []
    })

    const updateQuantity = (item, newQuantity) => {
      orderStore.updateItemQuantity(item, newQuantity)
    }

    onMounted(() => {
      const scrollableElement = document.querySelector('#menuScroll')
      if (scrollableElement) {
        scrollableElement.addEventListener('scroll', handleScroll)
      }
    })

    onUnmounted(() => {
      const scrollableElement = document.querySelector('#menuScroll')
      if (scrollableElement) {
        scrollableElement.removeEventListener('scroll', handleScroll)
      }
    })

    return {
      activeCategory,
      restaurant,
      filteredMenuItems,
      totalQuantity,
      selectedItems,
      updateQuantity,
      showOverlay,
      show,
      currentImage,
      showPromo,
      isScrolled,
      handleScroll,
    }
  },
}
</script>

<style scoped>
.van-overlay {
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(64px);
}

.van-sidebar-item--select::before {
  top: unset !important;
  bottom: 0 !important;
  left: 50% !important;
  height: 0;
  width: 0;
  transform: translatex(-50%) !important;
  background-color: #7042ea !important;
  animation: selected 300ms ease-out forwards;
  animation-delay: 100ms;
  border-radius: 50px 50px 0 0;
}

@keyframes selected {
  0% {
    width: 0px;
    height: 3px;
  }
  100% {
    width: 50%;
    height: 3px;
  }
}

.van-cell::after {
  border: 1px solid #070707;
}
</style>
